import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import { TimeSeconds } from 'utils/helpers'
import moment from 'moment'
import { useSendbirdStateContext, sendBirdSelectors } from 'sendbird-uikit'
import { PROGRAM_IDS } from '../../../constants'
import {
	ButtonGroup,
	Checkbox,
	CircularProgress,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	Input,
	InputLabel,
	LinearProgress,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	MenuItem,
	Select,
	Stack,
} from '@mui/material'
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from '@mui/lab'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Typography from 'typography'
import { Label, Download, NavigateBefore, NavigateNext } from '@mui/icons-material'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'
import SurveyMenu from './SurveyMenu'
import { programExport } from '../../../apis/export'
import { sendNotification } from '../../../apis/notification'
import { navigate } from 'gatsby'

const PREFIX = 'ProgramTable'

const classes = {
	root: `${PREFIX}-root`,
	container: `${PREFIX}-container`,
	rows: `${PREFIX}-rows`,
	visuallyHidden: `${PREFIX}-visuallyHidden`,
	button: `${PREFIX}-button`,
	tableHeader: `${PREFIX}-tableHeader`,
	compactSearch: `${PREFIX}-compactSearch`,
	appBar: `${PREFIX}-appBar`,
	textGrey: `${PREFIX}-textGrey`,
	borderGrey: `${PREFIX}-borderGrey`,
}

const StyledPaper = styled(Paper)(({ theme }) => ({
	[`&.${classes.root}`]: {
		width: '100%',
		overflowX: 'auto',
		padding: theme.spacing(4),
	},

	[`& .${classes.container}`]: {
		overflowX: 'auto',
		maxHeight: `calc(100vh - 320px)`,
		minHeight: 400,
	},

	[`& .${classes.rows}`]: {
		flexDirection: 'column',
	},

	[`& .${classes.visuallyHidden}`]: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},

	[`& .${classes.button}`]: {
		borderColor: 'rgba(255, 255, 255, 0.7)',
	},
	[`& .${classes.compactSearch}`]: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	[`& .${classes.tableHeader}`]: {
		borderBottom: '0px',
	},
	[`& .${classes.appBar}`]: {
		marginBottom: '12px',
	},
}))

const StyledDialog = styled(Dialog)(({ theme }) => ({
	[`& .${classes.borderGrey}`]: {
		borderColor: theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[500],
	},
	[`& .${classes.textGrey}`]: {
		color: theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.grey[400],
	},
}))

const columns = [
	{
		id: 'select',
		label: null,
	},
	{
		id: 'name',
		label: 'Subject',
		format: (name, email) => (
			<span style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem' }}>
				<span style={{ fontWeight: 800 }}>{name || 'Unknown'}</span>
				{email && <span style={{ fontSize: '.8rem', color: '#999' }}>{email}</span>}
			</span>
		),
		sortVal: (patient) => patient?.User?.name?.toLowerCase() || '',
	},
	{
		id: 'created_at',
		label: 'Participant Since',
		format: (value) => {
			return moment(value).format('YYYY-MM-DD')
		},
		sortVal: (patient) => {
			return patient?.User?.created_at ? new Date(patient?.User?.created_at) : null
		},
	},
	{
		id: 'last_login',
		label: 'Activity',
		format: (value) => {
			const now = new Date().getTime()
			const time = new Date(value).getTime()
			return TimeSeconds((now - time) / 1000, 1)
		},
		sortVal: (patient) => {
			return patient?.User?.last_login ? new Date(patient?.User?.last_login) : null
		},
	},
	{
		id: 'phoneNumber',
		label: 'Phone',
		format: (value) => {
			if (value) {
				let phone = value?.phoneNumber
				return phone || '-'
			}
			return ''
		},
		sortVal: (value) => {
			return value?.User?.metadata?.phoneNumber ? value?.User?.metadata?.phoneNumber : null
		},
	},
	{
		id: 'colonoscopyDate',
		label: 'Colonoscopy at',
		format: (value) => {
			if (value?.colonoscopyDate) {
				return moment.utc(value?.colonoscopyDate).format('MM/DD, HH, z')
			}
			return null
		},
		sortVal: (value) => {
			return value?.User?.metadata?.colonoscopyDate
				? new Date(value?.User?.metadata?.colonoscopyDate)
				: new Date(3000, 12, 20)
		},
	},
	{
		id: 'chat',
		label: 'Chat',
		format: (value) => value,
	},
	{
		id: 'monitor',
		label: 'Monitor',
		format: (value) => value,
	},
	// {
	// 	id: 'survey',
	// 	label: 'Survey',
	// 	format: (value) => value,
	// },
]

function getDefaultDateTime() {
	return moment().hour(9).minutes(0).seconds(0).milliseconds(0)
}

function SurveyDialog({ open, setOpen, programSurveys, addProgramSurvey, selectedUsers, setSelectedUsers }) {
	const [id, setId] = React.useState(programSurveys?.[0]?.id)
	const [datetime, setDatetime] = React.useState(getDefaultDateTime())
	const [loading, setLoading] = React.useState(false)
	const [success, setSuccess] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState(null)

	const handleReset = () => {
		setLoading(false)
		setSuccess(false)
		setErrorMessage(null)
	}

	const handleClose = () => {
		handleReset()
		setOpen(false)
	}

	const handleChange = (event) => {
		setId(event.target.value)
	}

	const handleSubmit = () => {
		if (loading) return
		setLoading(true)
		setErrorMessage(null)

		const data = {
			user_key: selectedUsers.map((v) => v.key),
			program_survey_id: id,
			occurred_at: moment(datetime).toISOString(),
			_app: {
				type: 'user_program_survey',
			},
		}

		addProgramSurvey(data)
			.then((response) => {
				setSuccess(!!response)
				setErrorMessage(null)
			})
			.catch((err) => {
				setSuccess(false)
				setErrorMessage(err.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if (open) {
			setId(programSurveys?.[0]?.id)
			setDatetime(getDefaultDateTime())
		}
	}, [open])

	if (!open) return null

	return (
		<StyledDialog onClose={handleClose} open={open}>
			<div
				className={classes.borderGrey}
				style={{ display: 'flex', borderBottomWidth: 1, borderBottomStyle: 'solid' }}
			>
				<DialogTitle>{success ? 'Assign a Survey' : 'Assign a Survey'}</DialogTitle>
				<Button onClick={handleClose} style={{ marginLeft: 'auto' }}>
					<FontAwesomeIcon icon={faTimes} />
				</Button>
			</div>

			{success ? (
				<div style={{ padding: 20 }}>
					<div>
						Success! The survey "<b>{programSurveys?.find((item) => item.id === id)?.name}</b>" has been
						assigned to the following <b>{selectedUsers.length}</b> user(s) for{' '}
						<b>
							<span style={{ whiteSpace: 'nowrap' }}>{moment(datetime).format('LL')}</span>
						</b>
						:
					</div>
					<div
						style={{
							maxHeight: '140px',
							overflowY: 'auto',
							marginTop: 20,
							padding: '4px 14px',
							border: '1px solid #cbcbcb',
							borderRadius: 8,
						}}
					>
						{selectedUsers.map((v, i) => {
							return (
								<div
									key={v.id}
									style={{
										marginBottom: 10,
										paddingTop: 10,
										borderTop: i > 0 ? '1px solid #cbcbcb' : null,
									}}
								>
									<div style={{ lineHeight: 1.2, fontWeight: 600, marginBottom: 2 }}>{v.name}</div>
									<div style={{ lineHeight: 1.2, fontSize: '0.8rem', opacity: 0.7 }}>{v.email}</div>
								</div>
							)
						})}
					</div>
				</div>
			) : (
				<div style={{ padding: 20, width: 400 }}>
					{errorMessage ? <div style={{ marginBottom: 20, color: 'red' }}>{errorMessage}</div> : null}

					<FormControl fullWidth style={{ marginTop: 4, marginBottom: 24 }}>
						<InputLabel id="id">Program Survey</InputLabel>
						<Select
							id="id"
							value={id}
							label="Program Survey"
							onChange={handleChange}
							style={{ width: '100%' }}
						>
							{programSurveys?.map((survey) => (
								<MenuItem key={survey.id} value={survey.id}>
									{survey.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl fullWidth style={{ marginBottom: 20 }}>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DateTimePicker
								label="Survey Date/Time"
								value={datetime}
								onChange={(value) => {
									setDatetime(value)
								}}
								renderInput={(params) => <TextField {...params} style={{ width: '100%' }} />}
							/>
						</LocalizationProvider>
					</FormControl>

					<div>
						<div
							className={classes.textGrey}
							style={{
								fontWeight: '500',
								marginBottom: 4,
								fontSize: '0.75rem',
								display: 'flex',
								flexDirection: 'row',
								paddingLeft: '12px',
							}}
						>
							<div>Selected Users</div>
							<div style={{ marginLeft: 4 }}>({selectedUsers.length})</div>
						</div>

						<div
							className={classes.borderGrey}
							style={{
								maxHeight: '140px',
								overflowY: 'auto',
								padding: '4px 14px',
								borderWidth: 1,
								borderStyle: 'solid',
								borderRadius: 8,
							}}
						>
							{selectedUsers.map((v, i) => {
								return (
									<div
										className={classes.borderGrey}
										key={v.id}
										style={{
											marginBottom: 10,
											paddingTop: 10,
											borderTopWidth: i > 0 ? 1 : null,
											borderTopStyle: i > 0 ? 'solid' : null,
										}}
									>
										<div style={{ lineHeight: 1.2, fontWeight: 600, marginBottom: 2 }}>
											{v.name}
										</div>
										<div style={{ lineHeight: 1.2, fontSize: '0.8rem', opacity: 0.7 }}>
											{v.email}
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			)}

			<div
				className={classes.borderGrey}
				style={{
					width: '100%',
					display: 'flex',
					borderTopWidth: 1,
					borderTopStyle: 'solid',
					padding: '12px 20px',
					backgroundColor: 'rgba(0,0,0,0.05)',
				}}
			>
				{success ? (
					<Button variant="contained" style={{ marginLeft: 'auto' }} onClick={handleClose}>
						Close
					</Button>
				) : (
					<Button variant="contained" style={{ marginLeft: 'auto' }} onClick={handleSubmit}>
						{loading ? 'Saving...' : 'Submit'}
					</Button>
				)}
			</div>
		</StyledDialog>
	)
}

function NotificationDialog({ open, setOpen, selectedUsers, setSelectedUsers }) {
	const [loading, setLoading] = React.useState(false)
	const [success, setSuccess] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState(null)
	const [title, setTitle] = useState('')
	const [message, setMessage] = useState('')
	const [logo, setLogo] = useState('')

	const handleReset = () => {
		setLoading(false)
		setSuccess(false)
		setErrorMessage(null)
	}

	const handleClose = () => {
		handleReset()
		setOpen(false)
	}

	const onPressSendNotification = async () => {
		setLoading(true)
		// Send notifcation code here
		const userIds = JSON.stringify(selectedUsers.map((user) => user.key))
		await sendNotification({
			title: title,
			message: message,
			userIds: userIds,
			imageUrl: logo,
		})
		setTitle('')
		setMessage('')
		setLogo('')
		setSelectedUsers([])
		setLoading(false)
		handleClose()
	}

	if (!open) return null

	return (
		<StyledDialog open={open} onClose={handleClose}>
			<Stack direction={'row'} justifyContent={'space-between'} borderBottom={1} borderColor={'gainsboro'}>
				<DialogTitle>Send Notification</DialogTitle>
				<Button onClick={handleClose} style={{ marginLeft: 'auto' }}>
					<FontAwesomeIcon icon={faTimes} />
				</Button>
			</Stack>
			{loading && <LinearProgress />}
			<Stack padding={2} gap={2} minWidth={400} borderBottom={1} borderColor={'gainsboro'}>
				<TextField
					disabled={loading}
					value={title}
					onChange={(e) => setTitle(e.target.value)}
					label="Title"
					size="small"
				/>
				<TextField
					disabled={loading}
					value={message}
					onChange={(e) => setMessage(e.target.value)}
					label="Message"
					multiline
					size="small"
				/>
				<TextField
					disabled={loading}
					value={logo}
					onChange={(e) => setLogo(e.target.value)}
					label="Image URL"
					size="small"
				/>
				{logo && <img src={logo} height={100} width={100} style={{ alignSelf: 'center' }} />}
			</Stack>
			<Stack paddingX={2} paddingY={1} flexDirection={'row'} justifyContent={'end'}>
				<Button disabled={!title} variant="contained" onClick={onPressSendNotification}>
					Send
				</Button>
			</Stack>
		</StyledDialog>
	)
}

function EnhancedTableHead(props) {
	const { order, orderBy, rowCount, onRequestSort, selectAll, onSelectAll } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				<TableCell key={'select'} align={'left'} padding={'none'} sortDirection={false}>
					<Checkbox checked={selectAll} onChange={(e) => onSelectAll(e.target.checked)} />
				</TableCell>
				{columns
					.filter((v) => v.id !== 'select')
					.map((headCell) => (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? 'right' : 'left'}
							padding={headCell.disablePadding ? 'none' : 'normal'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							{headCell.sortVal ? (
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={order}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<span className={classes.visuallyHidden}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</span>
									) : null}
								</TableSortLabel>
							) : (
								headCell.label
							)}
						</TableCell>
					))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	[`&.${classes.root}`]: {
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	[`&:hover`]: {
		backgroundColor: 'rgb(240,240,240)',
	},
}))

export default function ProgramTable({
	patient: patients,
	programSurveys,
	addProgramSurvey,
	setCurrentPatient,
	fetchPatientPicture,
	onSetCurrentPatient,
	onChatPatient,
	onChangePageSize,
	currentProgram,
	getStudies,
	searchValue,
	setSearchValue,
	sortPreferences,
	setSortPreferences,
	pageNumberFromURL,
	page,
	setPageNumberFromURL,
	setPage,
	searchString,
	setSearchString,
}) {
	const [pageSizeFromURL, setPageSizeFromURL] = useQueryParam('size', NumberParam)

	const sortDirection = sortPreferences.direction
	const sortColumn = sortPreferences.column

	const [sortedFilteredPatients, setSortedFilteredPatients] = React.useState(patients)
	const [largeDataWarning, setLargeDataWarning] = useState(false)
	const [isExporting, setIsExporting] = useState(false)

	// const [searchVal, setSearchVal] = React.useState('')

	/**
	 * Pulling these two out of the table because we want to switch the page
	 * to the the first one when we switch the program.
	 * Source of event is Dropdown, responsible for program change.
	 */
	// const [pageNumberFromURL, setPageNumberFromURL] = useQueryParam('page', NumberParam)
	// const [page, setPage] = useState(pageNumberFromURL || 0)

	const [rowsPerPage, setRowsPerPage] = useState(pageSizeFromURL || 100)

	const [open, setOpen] = React.useState(false)
	const [openNotification, setOpenNotification] = React.useState(false)
	const [selectedUsers, setSelectedUsers] = React.useState([])
	const [selectAll, setSelectAll] = React.useState(false)

	const context = useSendbirdStateContext()
	const sdkInstance = sendBirdSelectors.getSdk(context)

	const handleRequestSort = (event, property) => {
		const isAsc = sortColumn === property && sortDirection === 'asc'
		setSortPreferences({
			column: property,
			direction: isAsc ? 'desc' : 'asc',
		})
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
		setPageNumberFromURL(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPageSizeFromURL(+event.target.value)
		setPage(0)
		setPageNumberFromURL(0)
	}

	const handleOpen = () => {
		setOpen(true)
	}

	const handleOpenNotification = () => {
		setOpenNotification(true)
	}

	const handleSelectUser = (patient, checked) => {
		// Filter out selected user (bonus: avoids duplicates)
		const users = selectedUsers.filter((v) => v.key !== patient.key)

		// Add user only if checked
		if (checked) users.push(patient)

		// Update state
		setSelectAll(false)
		setSelectedUsers(users)
	}

	const handleSelectAllUsers = (checked) => {
		const users = checked ? patients?.map((v) => v.User) || [] : []

		setSelectAll(checked)
		setSelectedUsers(users)
	}

	const sortTable = useCallback((patientsArr, order, orderBy) => {
		if (order && orderBy) {
			return patientsArr.sort((a, b) => {
				const column = columns.find(({ id }) => id === orderBy)

				const valA = column.sortVal(a)
				const valB = column.sortVal(b)

				if (order === 'desc') {
					return valA < valB ? 1 : -1
				} else {
					return valA < valB ? -1 : 1
				}
			})
		}

		return patientsArr
	})

	const filterTable = useCallback((patientsArr, query) => {
		return query
			? patientsArr.filter((patient) => {
					const lowerQuery = query.toLowerCase()
					const fieldsToCheck = [
						patient.User.name,
						patient.User.email,
						moment(patient.User.created_at).format('YYYY-MM-DD'),
						moment(patient.User.last_login).format('YYYY-MM-DD'),
					]

					return fieldsToCheck.some((field) => {
						return field?.toLowerCase()?.includes(lowerQuery)
					})
			  })
			: patientsArr || []
	})

	const onSearched = () => {
		// getStudies({
		// 	program_id: currentProgram.id,
		// 	include_users: 1,
		// 	upage: page,
		// 	page_size: rowsPerPage,
		// 	searchString: searchValue,
		// })
	}

	function base64toBlob(base64) {
		const binary = atob(base64)
		const array = []
		for (let i = 0; i < binary.length; i++) {
			array.push(binary.charCodeAt(i))
		}
		return new Blob([new Uint8Array(array)], { type: 'application/zip' })
	}

	function downloadBlob(blob) {
		const url = window.URL.createObjectURL(blob)
		const a = document.createElement('a')
		a.href = url
		a.download = `${currentProgram?.code}-${new Date().toLocaleDateString()}.zip`
		a.click()
		window.URL.revokeObjectURL(blob)
	}

	const onExportData = async () => {
		setLargeDataWarning(false)
		setIsExporting(true)
		let userIds = selectedUsers.map((user) => user.id)
		let response = await programExport(userIds)
		const blob = base64toBlob(response.data)
		downloadBlob(blob)
		setIsExporting(false)
	}

	const showLargeDataExportAlert = () => {
		setLargeDataWarning(true)
	}

	useEffect(() => {
		const filtered = filterTable(patients, searchValue)
		const sorted = sortTable(filtered, sortDirection, sortColumn)
		setSortedFilteredPatients([...sorted])

		if (searchString && searchString.length > 0) {
			setSearchValue(searchString)
		}
	}, [patients, sortDirection, sortColumn, searchValue])

	return (
		<StyledPaper className={classes.root}>
			<AppBar className={classes.appBar} position="static" color="transparent" elevation={0}>
				<Toolbar style={{ padding: 0 }}>
					<Grid container gap={4}>
						<Grid item xs={5}>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									<SearchIcon className={classes.block} color="inherit" />
								</Grid>
								<Grid item xs>
									<TextField
										fullWidth
										value={searchValue}
										onChange={(event) => {
											setSearchValue(event.target.value)
											setSearchString(event.target.value)
										}}
										placeholder={'Search by name, email, or start date'}
										InputProps={{
											disableunderline: 'true',
											className: classes.searchInput,
											inputProps: {
												className: classes.compactSearch,
											},
										}}
									/>
								</Grid>
								{/* <Grid item>
							<Button onClick={onSearched} variant="contained">
								Search
							</Button>
						</Grid> */}
							</Grid>
						</Grid>

						<Grid item xs>
							<Stack direction="row" gap={2} justifyContent="flex-end" style={{ marginBottom: 10 }}>
								<ButtonGroup variant="contained">
									<Button
										disabled={!programSurveys?.length || !selectedUsers.length}
										variant="contained"
										color="primary"
										onClick={handleOpen}
									>
										Assign Survey
									</Button>
									<SurveyMenu
										disabled={!programSurveys?.length}
										program_id={currentProgram?.id}
										items={programSurveys?.map(({ name, jotform_form_id }) => ({
											name,
											jotform_form_id,
										}))}
									/>
								</ButtonGroup>
								<Button
									disabled={!selectedUsers.length}
									variant="contained"
									color="primary"
									onClick={handleOpenNotification}
								>
									Notify Users
								</Button>
								<Button
									variant="contained"
									onClick={selectedUsers.length < 1000 ? onExportData : showLargeDataExportAlert}
									disabled={!selectedUsers.length}
								>
									{!isExporting ? (
										<Download />
									) : (
										<CircularProgress style={{ marginRight: 10 }} size={15} color={'warning'} />
									)}
									<span>Export Data</span>
								</Button>
							</Stack>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{/* <Stack direction={'row-reverse'} margin={2}>
				<SSRPagination
					pageSize={rowsPerPage}
					setPageSize={setRowsPerPage}
					setCurrentPage={setPage}
					currentPage={page}
					currentProgram={currentProgram}
					getStudies={getStudies}
					searchValue={searchValue}
				/>
			</Stack> */}

			<TableContainer component={Paper} className={classes.container}>
				<Table size={'small'} stickyHeader aria-label="sticky table">
					<EnhancedTableHead
						classes={classes}
						order={sortDirection}
						orderBy={sortColumn}
						onRequestSort={handleRequestSort}
						rowCount={sortedFilteredPatients.length}
						selectAll={selectAll}
						onSelectAll={handleSelectAllUsers}
					/>
					<TableBody>
						{sortedFilteredPatients
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((patientRaw, index) => {
								const patient = patientRaw.User
								const intercomWorkspaceId =
									patientRaw.program_id === PROGRAM_IDS.METAMUCIL
										? process.env.INTERCOM_APP_ID_METAMUCIL
										: process.env.INTERCOM_APP_ID_DIETA
								return (
									<StyledTableRow role="checkbox" tabIndex={-1} key={patientRaw.id}>
										{columns.map((column) => {
											const value = patient[column.id]
											if (column.id === 'phoneNumber') {
												let metadata = patient['metadata']
												return (
													<TableCell key={column.id} align={column.align}>
														<Grid container direction="row" alignItems="center">
															{!!metadata && column?.format(metadata)}
														</Grid>
													</TableCell>
												)
											}

											if (column.id === 'colonoscopyDate') {
												let metadata = patient['metadata']
												return (
													<TableCell key={column.id} align={column.align}>
														<Grid container direction="row" alignItems="center">
															{!!metadata && column?.format(metadata)}
														</Grid>
													</TableCell>
												)
											}

											if (column.id === 'monitor') {
												return (
													<TableCell key={column.id} align={column.align}>
														<Grid container direction="row" alignItems="center">
															<Button
																variant="contained"
																color="primary"
																onClick={() => {
																	setCurrentPatient(patient, false, true)
																	onSetCurrentPatient()
																}}
															>
																Monitor
															</Button>
														</Grid>
													</TableCell>
												)
											}
											if (column.id === 'chat') {
												return (
													<TableCell key={column.id} align={column.align}>
														<Grid container direction="row" alignItems="center">
															<Button
																disabled={!patient.key}
																variant="contained"
																color="primary"
																onClick={() => {
																	navigate(`/chat/${patient.key}`)
																	//onChatPatient(patient, sdkInstance)
																	// const ChatWindow = window.open(
																	// 	`https://app.intercom.com/apps/${intercomWorkspaceId}/users/show?user_id=${patient.key}`,
																	// 	'',
																	// 	'menubar=1,resizable=1,width=1250,height=850,top=0,left=1000'
																	// )
																}}
															>
																Chat
															</Button>
														</Grid>
													</TableCell>
												)
											}
											if (column.id === 'survey') {
												return (
													<TableCell key={column.id} align={column.align}>
														<Grid container direction="row" alignItems="center">
															<Button
																disabled={!programSurveys?.length}
																variant="contained"
																color="primary"
																onClick={() => {
																	// setSelectedUser(patient)
																}}
															>
																Survey
															</Button>
														</Grid>
													</TableCell>
												)
											}

											if (column.id === 'name') {
												return (
													<TableCell key={column.id} align={column.align}>
														<Grid container direction="row" alignItems="center">
															<Avatar
																src={patient.picture}
																style={{
																	marginRight: 20,
																}}
																imgProps={{
																	onError: (e) => {
																		// fetchPatientPicture(patient)
																	},
																}}
															/>
															{column.format(patient.name, patient.email || patient.key)}
														</Grid>
													</TableCell>
												)
											}

											if (column.id === 'select') {
												const checked =
													selectedUsers.findIndex((v) => v.key === patient.key) >= 0
												const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
												return (
													<TableCell
														key={column.id}
														align={column.align}
														style={{
															padding: 0,
															width: 40,
														}}
													>
														<Checkbox
															{...label}
															value={patient.key}
															checked={checked}
															onChange={(e) =>
																handleSelectUser(patient, e.target.checked)
															}
															color="primary"
															inputProps={{ 'aria-label': 'controlled' }}
														/>
													</TableCell>
												)
											}

											return (
												<TableCell key={column.id} align={column.align}>
													<Grid container direction="row" alignItems="center">
														{column?.format(value) || value}
													</Grid>
												</TableCell>
											)
										})}
									</StyledTableRow>
								)
							})}
					</TableBody>
				</Table>
			</TableContainer>

			{/* <Stack direction={'row-reverse'} margin={2}>
				<SSRPagination
					pageSize={rowsPerPage}
					setPageSize={setRowsPerPage}
					setCurrentPage={setPage}
					currentPage={page}
					currentProgram={currentProgram}
					getStudies={getStudies}
					searchValue={searchValue}
				/>
			</Stack> */}
			<Stack direction={'row'} justifyContent={'space-between'} columnGap={2}>
				<div></div>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50, 100]}
					component="div"
					count={sortedFilteredPatients.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Stack>

			<SurveyDialog
				open={open}
				setOpen={setOpen}
				programSurveys={programSurveys}
				addProgramSurvey={addProgramSurvey}
				selectedUsers={selectedUsers}
				setSelectedUsers={setSelectedUsers}
			/>

			<StyledDialog open={largeDataWarning} onClose={() => setLargeDataWarning(false)}>
				<div
					className={classes.borderGrey}
					style={{ display: 'flex', borderBottomWidth: 1, borderBottomStyle: 'solid' }}
				>
					<DialogTitle>Warning</DialogTitle>
					<Button onClick={() => setLargeDataWarning(false)} style={{ marginLeft: 'auto' }}>
						<FontAwesomeIcon icon={faTimes} />
					</Button>
				</div>
				<Stack px={3} pt={3}>
					<div>You are about to export data for more than 1000 users. This might take a while</div>
				</Stack>
				<Stack p={2}>
					<Button disabled={!selectedUsers?.length} variant="contained" onClick={onExportData}>
						Proceed
					</Button>
				</Stack>
			</StyledDialog>

			<NotificationDialog
				open={openNotification}
				setOpen={setOpenNotification}
				selectedUsers={selectedUsers}
				setSelectedUsers={setSelectedUsers}
			/>
		</StyledPaper>
	)
}

function SSRPagination({
	pageSize,
	setCurrentPage,
	setPageSize,
	currentPage,
	currentProgram,
	getStudies,
	searchValue,
}) {
	const onPressNext = () => {
		setCurrentPage(currentPage + 1)
	}

	const onPressBefore = () => {
		setCurrentPage(currentPage - 1)
	}

	useEffect(() => {
		getStudies({
			program_id: currentProgram.id,
			include_users: 1,
			upage: currentPage,
			page_size: pageSize,
			// searchString: searchValue,
		})
	}, [currentPage])

	return (
		<Stack direction={'row'} alignItems={'center'} spacing={1}>
			<div>Rows per page:</div>
			<Select
				value={pageSize}
				onChange={(e) => {
					setCurrentPage(0)
					setPageSize(e.target.value)
				}}
				size="small"
			>
				<MenuItem value={10}>10</MenuItem>
				<MenuItem value={25}>25</MenuItem>
				<MenuItem value={50}>50</MenuItem>
				<MenuItem value={100}>100</MenuItem>
			</Select>
			<div>
				{(currentPage - 1) * pageSize + 1} -{' '}
				{pageSize + pageSize * currentPage > currentProgram.membersCount
					? currentProgram.membersCount
					: pageSize + pageSize * (currentPage - 1)}{' '}
				of {currentProgram.membersCount}
			</div>
			<IconButton onClick={onPressBefore} disabled={(currentPage - 1) * pageSize + 1 <= 1}>
				<NavigateBefore />
			</IconButton>
			<IconButton
				onClick={onPressNext}
				disabled={pageSize + pageSize * (currentPage - 1) > currentProgram.membersCount}
			>
				<NavigateNext />
			</IconButton>
		</Stack>
	)
}
